import axios from './axios';


export const generarQR = data => axios.post(`/api/v1/users/generarQR`, data); 

export const verificarQR = data => axios.post(`/api/v1/users/verificarQR`, data); 

export const consultarPagos = (data) => axios.post(`/api/v1/users/consultarPago`, data); 

export const reporte = (data) => axios.post(`/api/v1/reporte`, data);

export const reporteAll = (data) => axios.post(`/api/v1/reporte/all`, data);

export const loginRequest = user => axios.post(`/api/v1/auth/signin`, user);

export const listarUsuarios = (data) => axios.post(`/api/v1/users/listarUsuarios`, data);

export const obtDeuda = (data) => axios.post(`/api/v1/pagos/obtenerDeuda`, data);

export const obtClienteLote = (data) => axios.post(`/api/v1/pagos/obtenerClienteLote`, data);

export const regisUsuario = (data) => axios.post(`/api/v1/users/registrarUsuario`,data);

export const editUsuario = (data) => axios.post(`/api/v1/users/editarUsuario`,data);

export const eliminUsuario = (data) => axios.post(`/api/v1/users/eliminarUsuario`,data);

export const actiUsuario = (data) => axios.post(`/api/v1/users/activarUsuario`,data);

export const listPagos = (data) => axios.post(`/api/v1/pagos/listarPagos`, data);

export const listPagoNoEnviadoSap = (data) => axios.post(`/api/v1/pagos/listarPagoNoEnviadoSap`, data);

export const regisPago = (data) => axios.post(`/api/v1/pagos/registrarPago`, data);

export const enviarSAP = (data) => axios.post(`/api/v1/pagos/enviarPagoSAP`, data);

export const filtrPagos = (data) => axios.post(`/api/v1/pagos/filtrarPagos`, data);

export const filtrPagoNoEnviadoSap = (data) => axios.post(`/api/v1/pagos/filtrarPagoNoEnviadoSap`, data);

export const listBanco = (data) => axios.post(`/api/v1/pagos/listarBanco`, data);

export const listFactura = (data) => axios.post(`/api/v1/pagos/listarFacturaWeb`, data);

export const enviarFacturasSAP = (data) => axios.post(`/api/v1/pagos/EnviarPagoNoSicronizadoSAPWeb`, data);