import React, { useState, useEffect } from 'react'
import { useDeudas } from '../context/DeudasContext'
import { useForm } from 'react-hook-form';
import { Link, useNavigate} from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Pdf from '../components/Pdf';
import moment from 'moment';

import { PDFDownloadLink } from '@react-pdf/renderer';

export const HistoricoPagos = () => {

  const { isAuthenticated, user, obtenerDeuda, deudas, pagos, registrarPago, listaUsuarios, filtrarPagos, errorSap, setErrorSap, enviarPagoSAP,loadingLogin } = useDeudas();
  const [todosUsuarios, setTodosUsuarios] = useState(0);
  const [opcionesSeleccionadas, setOpcionesSeleccionadas] = useState([]);
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaFin, setFechaFin] = useState('');
  const [codigoCliente, setCodigoCliente] = useState('');

  const fechaActual = moment().format('YYYY-MM-DD');
  

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = pagos==null? 0 : Math.ceil(pagos.length / 10);
  const startIndex = (currentPage - 1) * 10;
  const endIndex = startIndex + 10;
  const currentItems =pagos==null? [] : pagos.slice(startIndex, endIndex);
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };
  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };
  
  const filtrar = () => {
    const stringSelecValues = opcionesSeleccionadas.join(',');
    let fechaIn = fechaInicio;
    let fechaF = fechaFin;
    if(fechaInicio == '' ){
      fechaIn = fechaActual;
    }
    if(fechaFin == ''){
      fechaF = fechaActual;
    }
    if(user.idRol == 5){
      const data = {
        "fechaInicio": fechaIn,
        "fechaFin": fechaF,
        "usuarios": stringSelecValues,
        "codigoCliente": codigoCliente,
        "sesion" : user.sesion,
      }
      filtrarPagos(data);
    }else{
      const usuario = `'${user.usuario}'`;
      const data = {
        "fechaInicio": fechaIn,
        "fechaFin": fechaF,
        "usuarios": usuario,
        "codigoCliente": codigoCliente,
        "sesion" : user.sesion,
      }
      filtrarPagos(data);
    }
  }

  const ponerTodosUsuarios = ()=>{
    if(todosUsuarios == 0){
      setTodosUsuarios(1);
      const usuarios = [];
     if(listaUsuarios != null){
       listaUsuarios.forEach(usuario => {
       usuarios.push(`'${usuario.usuarioWebLogin}'`);
       })

       setOpcionesSeleccionadas(usuarios)
    
     }
    }else{
      setTodosUsuarios(0);
      setOpcionesSeleccionadas([])
    }
    

  }

  const selecUsuarios = (value)=>{
    //console.log(value)
    const usuario = `'${value}'`
   if (opcionesSeleccionadas.includes(usuario)) {
      // Si el valor ya está seleccionado, lo removemos del array de opciones seleccionadas
      setOpcionesSeleccionadas(opcionesSeleccionadas.filter(opcion => opcion !== usuario));
      setTodosUsuarios(0);
    } else {
      // Si el valor no está seleccionado, lo agregamos al array de opciones seleccionadas
      setOpcionesSeleccionadas([...opcionesSeleccionadas, usuario]);
    }
    
    //const stringSelecValues = selecValues.join(',');
   /* const selecValues = Array.from(value.selectedOptions, (option) => `'${option.value}'`);
    const stringSelecValues = selecValues.join(',');
    setSelecOpciones(stringSelecValues);
    console.log(stringSelecValues)*/
  /*  const bcrypt = require('bcryptjs');

// Hash bcrypt almacenado
const hash = "$2y$12$yXQK7mMijWebRZZ.q9uS5uRVDGG9gBCq2F.w/w5b.0buMpf.76mFq";

// Contraseña proporcionada por el usuario
const contraseña = "12345678";

// Verificar la contraseña
bcrypt.compare(contraseña, hash, function(err, result) {
    if (err) {
        console.error("Error al comparar las contraseñas:", err);
    } else {
        if (result) {
            console.log("¡La contraseña es correcta!");
        } else {
            console.log("La contraseña es incorrecta.");
        }
    }
});*/
  }
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const enviarSAP = (data) => {
    data.sesion = user.sesion;
    enviarPagoSAP(data);
  }

  useEffect(() => {
    if(errorSap != ''){
      setTimeout(() => {
        setErrorSap('')
        }, 10000);
    }
    
  },[errorSap,loadingLogin])

  return (
    <>
    <div className="card shadow" style={{margin: '1%', marginRight:'2%',width: '100%'}}>
  <div className="card-header"> <Row>
      <Col> <p className='title'>HISTÓRICO PAGOS</p></Col>
      <Col>
        <div className="d-flex justify-content-end">
            <div>
                <ReactHTMLTableToExcel 
                  table = "tablapagos"
                  filename="pagos"
                  sheet="Pagina 1"
                  className="btn btn-success btn-sm fas fa-file-excel"
                  buttonText=" Excel"
                />
            </div>
        </div>
    </Col>
    </Row>
    <div style={{textAlign : 'left'}}>
    <div className="row">
        <div className="col-auto">
            <label className="col-form-label">Fecha Inicio</label>
            <input type="date" id="inputPassword6" className="form-control" value ={ fechaInicio == ''? fechaActual : fechaInicio} onChange={(e)=>setFechaInicio(e.target.value)} />
        </div>
        <div className="col-auto">
            <label className="col-form-label">Fecha Final</label>
            <input type="date" id="inputPassword6" className="form-control" value ={ fechaFin == ''? fechaActual : fechaFin} onChange={(e)=>setFechaFin(e.target.value)}/>
        </div>
        <div className="col-auto" style={{display: user.idRol == 5? 'block': 'none'}}>
            <button className="form-select mt-2" onClick={()=>toggleDropdown()}>{isOpen ? 'Cerrar Selección de vendedores' : 'Abrir Selección de vendedores'}</button>
            <div className="form-control" style={{ display: isOpen ? 'block' : 'none', width: '100%' }}> 
              <div className="form-check">
                <input className="form-check-input" onChange={()=>ponerTodosUsuarios()} type="checkbox" defaultValue="Todos" checked={todosUsuarios}/>
                  <label className="form-check-label" >
                    Todos
                  </label>
                 {listaUsuarios == null ? " " : (listaUsuarios.map( (usuario, indice) => (
                  <div onChange={(e)=>selecUsuarios(e.target.value)} key={indice}>
                    <input className="form-check-input" type="checkbox" value={usuario.usuarioWebLogin} checked={opcionesSeleccionadas.includes(`'${usuario.usuarioWebLogin}'`)? true : false} readOnly/>
                    <label className="form-check-label" >
                      {usuario.usuarioWebLogin}
                    </label>
                  </div>
                  )))}
              </div>
            </div>
        </div>
        <div className="col-auto">
            <label className="col-form-label">Código Cliente</label>
            <input type="text" className="form-control" onChange={(e)=>setCodigoCliente(e.target.value)}/>
        </div>
        <div className="col-auto">
          <div className='col-form-label'></div>
          <div className='col-form-label'></div>
            <button onClick={()=>filtrar()} className='btn btn-info btn-sm m-2'><i className='fas fa-search'> Filtrar </i></button>
        </div>
    </div>
    </div>
   
    </div>
    <div className="card-body" style={{textAlign:'left'}}>
    {errorSap !=''?  <div className="alert alert-danger" role="alert">{errorSap}</div> : <div></div>}
    <div className="table-responsive mt-2" >
      <table className="table table-hover">
        <thead>
          <tr>
            <th>NroInterno</th>
            <th>Fecha</th>
            <th>Codigo</th>
            <th>Cliente</th>
            <th>Lote</th>
            <th>Importe</th>
            <th>Nro Deposito</th>
            <th>Vendedor</th>
            <th>Estado</th>
            <th>Nro Documento</th>
            <th>Nro Doc (SAP)</th>
            <th>Enviar SAP</th>
            <th>Imprimir</th>
          </tr>
        </thead>
        <tbody>
        {pagos == null ? null : (currentItems.map( (pago, indice) => (
          <tr key={indice}>
           <td>{pago.id}</td>
            <td>{new Date(pago.fechaCreacion).getDate()}/{new Date(pago.fechaCreacion).getMonth()+1}/{new Date(pago.fechaCreacion).getFullYear()}</td>
            <td>{pago.codigoCliente}</td>
            <td>{pago.nombreCliente}</td>
            <td>{pago.lote}</td>
            <td>{pago.importe}</td>
            <td>{pago.nroDeposito}</td>
            <td>{pago.nombreUsuario}</td>
            <td>{pago.enviadoSAP == 'F' ?'No sincronizado':pago.enviadoSAP == 'C'? 'Cancelado':'Sincronizado'}</td>
            <td>{pago.nroDocumentoSAP}</td>
            <td>{pago.nroDocumento}</td>
            <td>{pago.enviadoSAP == 'F' ? <button onClick={()=>enviarSAP(pago)} disabled={loadingLogin} className='btn btn-outline-primary m-2 btn-sm'><i className='fas fa-paper-plane'></i></button> :''}</td>
            <td>
            {pago.enviadoSAP == 'F' || pago.enviadoSAP == 'C'? '' :
              <PDFDownloadLink document={<Pdf content={pago} tipo={pago.tipoPago=='CUO'? 'Cuota':'Multa'}/>} fileName="ComprobantePagos.pdf">
                {({loading, url, error, blob}) => 
                loading ? <button className='btn btn-outline-secondary m-2 btn-sm'><i className='fas fa-print'></i></button> : (<button className='btn btn-outline-secondary m-2 btn-sm'><i className='fas fa-print'></i></button>)
                }
              </PDFDownloadLink>
            }
            </td>
          </tr>
        )))}
        </tbody>
      </table>
    </div>
  </div>
  <table id="tablapagos" style={{display: 'none'}}>
        <thead>
          <tr>
            <th>NroInterno</th>
            <th>Fecha</th>
            <th>Codigo</th>
            <th>Cliente</th>
            <th>Lote</th>
            <th>Importe</th>
            <th>Vendedor</th>
            <th>Estado</th>
            <th>Nro Documento</th>
            <th>Nro Doc (SAP)</th>
            <th>Tipo pago</th>
            <th>Nro Cuota</th>
          </tr>
        </thead>
        <tbody>
        {pagos == null ? null : (pagos.map( (pago, indice) => (
          <tr key={indice}>
            <td>{pago.id}</td>
            <td>{new Date(pago.fechaCreacion).getDate()}/{new Date(pago.fechaCreacion).getMonth()+1}/{new Date(pago.fechaCreacion).getFullYear()}</td>
            <td>{pago.codigoCliente}</td>
            <td>{pago.nombreCliente}</td>
            <td>{pago.lote}</td>
            <td>{pago.importe}</td>
            <td>{pago.nombreUsuario}</td>
            <td>{pago.enviadoSAP == 'F' ?'No sincronizado':pago.enviadoSAP == 'C'? 'Cancelado':'Sincronizado'}</td>
            <td>{pago.nroDocumentoSAP}</td>
            <td>{pago.nroDocumento}</td>
            <td>{pago.tipoPago == 'CUO'? 'Cuota':'Multa'}</td>
            <td>{pago.nroCuota}</td>
           </tr>
        )))}
        </tbody>
  </table> 
  <nav>
    <ul className="pagination table-responsive">
      <li className={currentPage === 1? 'page-item m-0 disabled' : 'page-item m-0'}>
        <a className="page-link"  onClick={handlePrevPage}>Anterior</a>
      </li>
      <ul className='table-responsive p-0'>
      {pageNumbers.map((page) => (
      <li key={page} className={currentPage === page? 'page-item m-0 active' : 'page-item m-0'}><a className="page-link" onClick={() => handlePageClick(page)}>{page}</a></li>
      ))}
      </ul>
      
      <li className={currentPage === totalPages? 'page-item m-0 disabled' : 'page-item m-0'}>
        <a className="page-link" onClick={handleNextPage}>Siguiente</a>
      </li>
    </ul>
  </nav>
      
  <div style={{textAlign :'right'}}>
    <span>{`Página ${currentPage} de ${totalPages}`}</span>
  </div>
</div>
  
  </>
  )
}