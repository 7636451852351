import React from 'react';
import { Page, Text, View, Document, StyleSheet, Tspan, Font} from '@react-pdf/renderer';

Font.register({
  family: 'roboto negrita',
  src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmEU9fBBc9.ttf'
  
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: 50,
    fontSize: 14,
  },
  title: {
   textAlign: 'center',
   padding: 10,
   margin: 10,
   fontFamily:'roboto negrita',
  },
  subtitle: {
   fontFamily:'roboto negrita',
  },
  espacioAbajo: {
    paddingBottom: 12,
  },
  seccion: {
    margin: 10,
    flexGrow: 1
  },
  seccionA: {
    marginTop: 10,
    flex: 1
  },
  paralelo: {
    flexDirection: 'row'
  },
  margenIzquierdo: {
    marginLeft: 70,
  },
  bordeArriba: {
    borderTop: 2,
    marginTop: 10,
    paddingTop: 10,
  },
  espacioamplioArriba:{
    paddingTop: 70
  },
  lineaContainer: {
    width: '40%',
  },
  linea: {
    flexGrow: 1,
    borderBottomWidth: 1,
  }
 

});

// Create Document Component
const Pdf = ({content, tipo}) => {
return (
    
  <Document>
    <Page size="Letter" style={styles.page}>
      
      <View style={styles.paralelo}>
        <View style={styles.seccion}>
          <Text style={{fontFamily:'roboto negrita'}}>URBANIZACIONES DEL NORTE S.A.</Text>
        </View>
        <View style={styles.seccionA}>
          <Text><Tspan style={styles.subtitle}> Nro Doc Sap :</Tspan></Text>
          <Text style={{marginTop:12}}><Tspan style={styles.subtitle}> Nro Doc :</Tspan></Text>
          <Text><Tspan style={styles.subtitle}> Fecha :</Tspan></Text>
        </View>
        <View style={styles.seccionA}>
          <View style={{textAlign: 'right'}}>
            <Text>{content.nroDocumento}</Text>
            <Text>{content.nroDocumentoSAP}</Text>
            <Text>{new Date(content.fechaCreacion).getDate()}/{new Date(content.fechaCreacion).getMonth()+1}/{new Date(content.fechaCreacion).getFullYear()}</Text>
          </View>
        </View>
      </View>
      <Text style={styles.title}>PAGO RECIBIDO</Text>
      <View style={styles.paralelo}><Text style={styles.subtitle}>Cliente: </Text><Text style={{marginLeft: 18}}>{content.codigoCliente} - {content.nombreCliente}</Text></View>
      
      <Text style={[styles.subtitle,styles.bordeArriba]}>Concepto</Text>
    
      <View style={styles.margenIzquierdo}>
        <Text style={styles.espacioAbajo}><Text>Tipo pago :</Text> {tipo}</Text>
        <Text style={styles.espacioAbajo}><Tspan >Lote: </Tspan> {content.lote}</Text>
        <Text style={styles.espacioAbajo}><Tspan >Nro cuota: </Tspan> {content.nroCuota}</Text>
        <Text style={styles.espacioAbajo}><Tspan >Banco: </Tspan> {content.banco.descripcion}</Text>
        <Text style={styles.espacioAbajo}><Tspan >Cta Banco: </Tspan> {content.banco.ctaBanco}</Text>
        <Text style={styles.espacioAbajo}><Tspan >Nro Deposito: </Tspan> {content.nroDeposito}</Text>
        <Text style={styles.espacioAbajo}><Text>Fecha de Deposito :</Text> {new Date(content.fechaDeposito).getDate()}/{new Date(content.fechaDeposito).getMonth()+1}/{new Date(content.fechaDeposito).getFullYear()}</Text>
        <Text style={styles.espacioAbajo}><Tspan >Total Pagado: </Tspan> Bs. {content.importe}</Text>
      </View>
      <View  style={[styles.paralelo,styles.espacioAbajo,styles.bordeArriba]}><Text>Autor: </Text><Text style={{marginLeft: 29}}>{content.nombreUsuario}</Text></View>
      <View style={[styles.paralelo, styles.espacioamplioArriba]}>
        <Text>Firma : </Text>
        <View style={styles.lineaContainer}>
          <Text style={styles.linea}> </Text>
        </View>
      </View>
    </Page>
  </Document>
);
};

export default Pdf;
