import { createContext, useContext, useState, useEffect } from "react";
import { 
    loginRequest,
    listarUsuarios,
    regisUsuario,
    editUsuario,
    eliminUsuario,
    actiUsuario,
    obtDeuda,
    obtClienteLote,
    listPagos,
    regisPago,
    enviarSAP,
    filtrPagos,
    filtrPagoNoEnviadoSap,
    listPagoNoEnviadoSap,
    listBanco,
    listFactura,
    enviarFacturasSAP,
 } from '../api/auth'

const DeudasContext = createContext();

export const useDeudas = () => {
    const context = useContext(DeudasContext);

    if (!context){
        throw new Error("useTask must be used within an AuthProvider");
    }
    return context;
}

export function DeudasProvider( {children} ){

    const [isAuthenticated, setIsAuthenticated] = useState(false); 
    const [loadingLogin, setLoadingLogin] = useState(false);
    const [user, setUser] = useState(null);  
    const [errorsLogin, setErrorsLogin] = useState([]); 
    const [listaUsuarios, setListaUsuarios] = useState([]);
    const [usuariosCargadosAlArray, setUsuariosCargadosAlArray] = useState(false);
    const [deudas, setDeudas] = useState(null);
    const [clienteLote, setClienteLote] = useState(null);
    const [pagosVer, setPagosVer] = useState(null);
    const [pagos, setPagos] = useState(null);
    const [errorSap, setErrorSap] = useState('');
    const [ErrorDeuda, setErrorDeuda] = useState('');
    const [confRegistroPago, setConfRegistroPago] = useState('');
    const [bancos, setBancos] = useState([]);
    const [facturas, setFacturas] = useState([]);
    const [nombreCliente, setNombreCliente] = useState('');

    const [columnasVerificadas, setColumnasVerificadas] = useState(null);

    const [deudasExcel, setDeudasExcel] = useState(null);
    const verificar =[];

    const signin = async (user) => {
       try {
            setLoadingLogin(true);
            const res = await loginRequest(user); 
            
            if (res.data.CodigoRespuesta === "0") {
                setIsAuthenticated(true);
                setUser(res.data.Data);
            }
            
            setLoadingLogin(false);
            setErrorsLogin([res.data.MensajeRespuesta]);
        } catch (error) {       
            setLoadingLogin(false);     
            console.log("Error");
             if(Array.isArray(error.response.data)){
                //return setErrors(error.response.data);
            }
            setErrorsLogin([error.response.data.message]); //convertimos a un arreglo el objeto message 
        }
    }

    const registrarUsuario =  async (data) => {
    
      data.sesion = user.sesion;
       const res = await regisUsuario(data);
       if (res.status == 200) {
        const dato = {
            "sesion": user.sesion,
        }
         listarUsuario(dato)
       }
      // console.log(res); 
    }

    const editarUsuario =  async (data, id) => {
    
     data.UsuarioWebId = id;
     data.sesion = user.sesion;
     const res = await editUsuario(data);
     if (res.status == 200) {
        const dato = {
            "sesion": user.sesion,
        }
        listarUsuario(dato)
      }
      // console.log(res); 
     }

     const eliminarUsuario = async (data) => {
        data.sesion = user.sesion;
        const res = await eliminUsuario(data);
        if (res.status == 200) {
            const dato = {
                "sesion": user.sesion,
            }
            listarUsuario(dato)
        }
     }

     const activarUsuario = async (data) => {
        data.sesion = user.sesion;
        const res = await actiUsuario(data);
        if (res.status == 200) {
            const dato = {
                "sesion": user.sesion,
            }
            listarUsuario(dato)
        }
     }

    const listarUsuario = async (data) =>{
        try {
            const res = await listarUsuarios(data); 
           if (res.status == 200) {
            if (res.data.CodigoRespuesta ==3){
                setIsAuthenticated(false)
             }else{
                setListaUsuarios(res.data.Data);
                setUsuariosCargadosAlArray(true) // actualizar
                setIsAuthenticated(true)
                //console.log(res.data.Data);
             }
            }
        } catch (error) {       
                
            console.log(error);
        }

    }

    const obtenerClienteLote = async (data) =>{
        try {
           // console.log(data)
            const res = await obtClienteLote(data);
            const deudasObtenidas = res.data.Data;
           // console.log(res)
             
            if (res.data.CodigoRespuesta ==3){
                document.getElementById('btnCerrarModal').click();
                setIsAuthenticated(false);
             }else{

                if(deudasObtenidas != null){
                    let nombreCliente = deudasObtenidas[0].NomClie;
                    //console.log(deudasObtenidas)
                    if (res.status == 200) {
                            setClienteLote(deudasObtenidas);
                            setNombreCliente(nombreCliente);
                            setErrorDeuda('');
                            setIsAuthenticated(true);
                    }
                }else{
                    setClienteLote(null);
                    const codigo = data.CodigoTipoBusqueda == 'COD' ? 'Código Cliente' : data.CodigoTipoBusqueda == 'DID'? 'Documento de identidad' : data.CodigoTipoBusqueda == 'CON'? 'Lote':'';
                    setErrorDeuda('No existe lote para ese cliente o es cliente inexistente para esa referencia de '+codigo);
                    setIsAuthenticated(true);
                }
             }
          
        } catch (error) {          
            console.log(error);
        }
        
    }

    const obtenerDeuda = async (data) =>{
        try {
           // console.log(data)
            const res = await obtDeuda(data);
            const deudasObtenidas = res.data.Data;
            //console.log(res)
             
            if (res.data.CodigoRespuesta ==3){
                document.getElementById('btnCerrarModal').click();
                setIsAuthenticated(false);
             }else{

                if(deudasObtenidas != null){
                
                let nombreCliente = deudasObtenidas[0].CLI_NOMBRE;
                let grupos = deudasObtenidas.reduce((acumulador, detalle) => {
                        let key = detalle.OVE_NROLOTE;
                        if (!acumulador[key]) {
                            acumulador[key] = {
                                lote: key,
                                valores: []
                            };
                        }
                        acumulador[key].valores.push(detalle);
                        return acumulador;
                    }, {});
                    let listaGrupos = Object.values(grupos);
                
                    if (res.status == 200) {
                            setDeudas(listaGrupos);
                           // setNombreCliente(nombreCliente);
                            //setErrorDeuda('');
                            setIsAuthenticated(true);
                    }
                }else{
                    setDeudas(null);
                  //  const codigo = data.CodigoTipoBusqueda == 'COD' ? 'Código Cliente' : data.CodigoTipoBusqueda == 'DID'? 'Documento de identidad' : data.CodigoTipoBusqueda == 'CON'? 'Lote':'';
                    //setErrorDeuda('No existe lote para ese cliente o es cliente inexistente para esa referencia de '+codigo);
                    setIsAuthenticated(true);
                }
             }
           /* let grupos = deudasObtenidas.reduce((mapa, detalle) => {
                let key = detalle.OVE_NROLOTE;
                let grupo = mapa.get(key);
                if (!grupo) {
                    grupo = [];
                    mapa.set(key, grupo);
                }
                grupo.push(detalle);
                return mapa;
            }, new Map());*/
           
          
        } catch (error) {          
            console.log(error);
        }
        
    }


    const obtenerDeudaExcel = async (data, lote) =>{
        
        try {
            const res = await obtDeuda(data);
           // console.log(data)
            //console.log(res)
            const deudasObtenidas = res.data.Data;
           // console.log(deudasObtenidas)
            if (res.data.CodigoRespuesta ==3){
                setIsAuthenticated(false);
             }else{
                if( deudasObtenidas != null){
                    //console.log(deudasObtenidas)
                   // console.log(lote)
                    const valor = deudasObtenidas.some(item => item.OVE_NROLOTE == lote)
                    //console.log(valor)
                    verificar.push(valor);
                    return valor
                    //console.log(verificar)
                }else{
                    verificar.push(false);
                    return false;
                }
             }

        } catch (error) {          
            console.log(error);
        }
        
    }

    const obtenerDeudaExcelVN = async (data, lote) =>{
        
        try {
            const res = await obtClienteLote(data);
           // console.log(data)
            //console.log(res)
            const deudasObtenidas = res.data.Data;
           // console.log(deudasObtenidas)
            if (res.data.CodigoRespuesta ==3){
                setIsAuthenticated(false);
             }else{
                if( deudasObtenidas != null){
                    //console.log(deudasObtenidas)
                   // console.log(lote)
                    const valor = deudasObtenidas.some(item => item.CodLote == lote)
                    //console.log(valor)
                    verificar.push(valor);
                    return valor
                    //console.log(verificar)
                }else{
                    verificar.push(false);
                    return false;
                }
             }

        } catch (error) {          
            console.log(error);
        }
        
    }

    const verifExiteLoteCliente = async(columns)=>{
        setLoadingLogin(true)
        for(const column of columns){
          let dato = {}
          let lote = "";
          let cuentaBanco="";
          
          const col = column.slice(0,6);
          let i = 0;
          for(i=0;i< col.length;i++){
            switch(i){
              case 0:
                dato.codigoTipoBusqueda = column[i];
                break;
              case 1:
                dato.codigoReferencia = column[i];
                //console.log(dato.codigoReferencia)
                break;
              case 2:
                lote = column[i];
                break;
              case 5:
                cuentaBanco = column[i];
                break;
            }
        }
        dato.sesion = user.sesion;
        const verificado = await obtenerDeudaExcelVN(dato, lote);
        
        const banco = bancos.filter(item => item.cuentaContable == cuentaBanco)
        
        //column.push(descripcion)
        let descripcion = false;
        if(banco.length > 0){
          descripcion = banco[0].descripcion;  
        }
        column.splice(3, 0, verificado);
        column.splice(7, 0, descripcion);
        if(descripcion && verificado){
            column.push(true);
        }else{
           column.push(false);
        }
      }
     // console.log(columns)
      setColumnasVerificadas(columns);
      setLoadingLogin(false)
    }

    const listarPagos = async (data)=>{
        try {
            let res = [];
            if(user.idRol == 5){
                data.usuarios = "";
                res = await listPagos(data);
            }
            if(user.idRol == 4){
                data.usuarios = `'${user.usuario}'`;
                res = await listPagos(data);
            }
           // console.log(res) 
            if (res.status == 200) {
                if (res.data.CodigoRespuesta ==3){
                    setIsAuthenticated(false)
                }else{
                    setPagos(res.data.Data);
                    setIsAuthenticated(true)
                }
            }
        } catch (error) {       
            console.log(error);
        }
    }

    const listarPagoVer = async (data)=>{
        try {
        
            data.usuarios = "";
            const  res = await listPagos(data);
           // console.log(res) 
            if (res.status == 200) {
                if (res.data.CodigoRespuesta ==3){
                    setIsAuthenticated(false);
                }else{
                    setPagosVer(res.data.Data);
                    setIsAuthenticated(true)
                }
                
            }
        } catch (error) {       
            console.log(error);
        }
    }

    const listarBanco = async (data)=>{
        try {
        
            const  res = await listBanco(data);
           
            if (res.status == 200) {
               setBancos(res.data.Data);
            }
        } catch (error) {       
            console.log(error);
        }
    }

    const listarFacturaWeb = async (data)=>{
        try {
            let res = []

            if(user.idRol == 5){
                data.usuarios = "";
                res = await listFactura(data);
            }
            if(user.idRol == 4){
                data.usuarios = `'${user.usuario}'`;
                res = await listFactura(data);
            }
           
            if (res.status == 200) {
                if (res.data.CodigoRespuesta ==3){
                    setIsAuthenticated(false);
                }else{
                    setFacturas(res.data.Data);
                    setIsAuthenticated(true)
                }
            }
        } catch (error) {       
            console.log(error);
        }
    }

    const registrarPago = async (data) => {
        const res = await regisPago(data);
        //console.log(res); 
        const mensaje = res.data.MensajeRespuesta;
        if (res.status == 200) {
            if(mensaje!='0' && mensaje != 'Referencia a objeto no establecida como instancia de un objeto.'){
               setConfRegistroPago("Pago Registrado Correctamente")
            } else{
                setErrorSap(mensaje);
            }
            const dato = {
                "sesion": user.sesion,
            }
            listarPagos(dato);
        }  
    }

    const registrarPagoExcel = async (data, indice) => {
        const res = await regisPago(data);
       // console.log(res); 
        const mensaje = res.data.MensajeRespuesta;
        if (res.status == 200) {
            if(mensaje!='0' && mensaje != 'Referencia a objeto no establecida como instancia de un objeto.'){
               setConfRegistroPago("Pago Registrado Correctamente")
            } else{
                setErrorSap(mensaje);
            }
            
            columnasVerificadas.splice(indice, 1);  
        }
     }    

    const enviarPagoSAP = async (data) => {
        setLoadingLogin(true);
        const res = await enviarSAP(data);
        const mensaje = res.data.MensajeRespuesta;
          
        if (res.status == 200) {
            
            if(mensaje!='0' && mensaje != 'Referencia a objeto no establecida como instancia de un objeto.'){
               
            } else{
                setErrorSap(mensaje);
            }
            const dato = {
                "sesion": user.sesion
            }
            listarPagos(dato);
        } 
        setLoadingLogin(false);
    }

    const enviarFacturaNoSincSAP = async (data) => {
        setLoadingLogin(true)
        const res = await enviarFacturasSAP(data);
        const mensaje = res.data.MensajeRespuesta;
          
        if (res.status == 200) {
            
            if(mensaje!='0' && mensaje != 'Referencia a objeto no establecida como instancia de un objeto.'){
                const data = {
                    "usuarios": "",
                    "sesion": user.sesion
                  }
                  listarFacturaWeb(data)

            } else{
                setErrorSap(mensaje);
            }
        } 
        setLoadingLogin(false)
    }

    const enviarPagoSAPNoSincronizados = async (data) => {
        setLoadingLogin(true);
        const res = await enviarSAP(data);
        const mensaje = res.data.MensajeRespuesta;
          
        if (res.status == 200) {
            
            if(mensaje!='0' && mensaje != 'Referencia a objeto no establecida como instancia de un objeto.'){
               
            } else{
                setErrorSap(mensaje);
            }
            const dato = {
                "sesion": user.sesion,
                "usuarios": "",
            }
            listarPagoNoEnviadoSap(dato);
        } 
        setLoadingLogin(false);
    }

    const filtrarPagos = async (data)=>{
        try {
            const res = await filtrPagos(data);
            if (res.status == 200) {
            setPagos(res.data.Data);
            setIsAuthenticated(true)
            }
        } catch (error) {       
            console.log(error);
        }
    }

    const filtrarPagosNoEnviadoSap = async (data)=>{
        try {
            const res = await filtrPagoNoEnviadoSap(data);
            if (res.status == 200) {
            setPagos(res.data.Data);
            setIsAuthenticated(true)
            }
        } catch (error) {       
            console.log(error);
        }
    }

    const listarPagoNoEnviadoSap = async (data)=>{
        try {
            let res = [];
            if(user.idRol == 5){
                data.usuarios = "";
                res = await listPagoNoEnviadoSap(data);
            }
            if(user.idRol == 4){
                data.usuarios = `'${user.usuario}'`;
                res = await listPagoNoEnviadoSap(data);
            }
            if (res.status == 200) {
                if (res.data.CodigoRespuesta ==3){
                    setIsAuthenticated(false);
                }else{
                    setPagos(res.data.Data);
                    setIsAuthenticated(true)
                }
            }
        } catch (error) {       
            console.log(error);
        }
    }

    const logout = () => {
        setIsAuthenticated(false)
        setUser(null)
        setListaUsuarios(null)
        setDeudas(null); 
        setPagos(null);
        setErrorDeuda('');
        setLoadingLogin(false);
        setErrorsLogin([]);
        setBancos(null);
    }

 /*   const getDeudas = async(data) => {        
        try {
            setLoading(true)
          //  const respuesta = await obtenerDeuda(data);

            if (respuesta.data.DetalleLotes.length <= 0 && respuesta.data.NombreCliente == ""){            
                setErrorConsultarDeuda(true);
            }else{
                setDeudas(respuesta.data) // actualizar
                setDeudasCargadasAlArray(true) // actualizar
                setIsAuthenticated(true)
            }
                 
            setLoading(false)
            // setErrorConsultarDeuda(false);
        } catch (error) {
            setErrorConsultarDeuda(true);
            console.log(error)
            setLoading(false)
        }
    }
    */

    useEffect(() => {
        if(errorsLogin.length > 0){
            const timer = setTimeout(() => {
                setErrorsLogin([]);
            }, 5000);
            return () => clearTimeout(timer); //quita el timer
        }
    }, [errorsLogin]);

    return (
        <DeudasContext.Provider value={{
            isAuthenticated,
            setIsAuthenticated,
            loadingLogin,
            setLoadingLogin,
            signin,
            user,
            setUser,
            errorsLogin,
            logout,
            registrarUsuario,
            listaUsuarios,
            listarUsuario,
            usuariosCargadosAlArray,
            setUsuariosCargadosAlArray,
            editarUsuario,
            eliminarUsuario,
            activarUsuario,
            obtenerDeuda,
            deudas,
            setDeudas,
            pagos,
            pagosVer,
            listarPagos,
            listarPagoVer,
            registrarPago,
            errorSap,
            setErrorSap,
            enviarPagoSAP,
            filtrarPagos,
            filtrarPagosNoEnviadoSap,
            listarPagoNoEnviadoSap,
            ErrorDeuda,
            setErrorDeuda,
            enviarPagoSAPNoSincronizados,
            confRegistroPago,
            setConfRegistroPago,
            bancos,
            listarBanco,
            nombreCliente,
            setNombreCliente,
            registrarPagoExcel,
            obtenerDeudaExcel,
            setDeudasExcel,
            deudasExcel,
            verifExiteLoteCliente,
            verificar,
            setColumnasVerificadas,
            columnasVerificadas,
            obtenerClienteLote,
            clienteLote,
            setClienteLote,
            listarFacturaWeb,
            facturas,
            enviarFacturaNoSincSAP,
            obtenerDeudaExcelVN,
        }}>            
            { children }
        </DeudasContext.Provider>
    );
}