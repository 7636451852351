import axios from 'axios'

//const API_SERVER_DEVELOPMENT = 'https://backendurbanorpago.banticapps.com'
//const API_SERVER_DEVELOPMENT = 'http://159.69.163.254/ServicioBCP'
const API_SERVER_DEVELOPMENT = 'http://localhost:15004'
//const API_SERVER_DEVELOPMENT = 'https://tst.backendurbanorpago.banticapps.com'
//const API_SERVER_PRODUCTION = 'https://backendurbanorpago.banticapps.com'
const API_SERVER_PRODUCTION = 'https://pagosmanual.azure.urbanor.com.bo'


//const API = process.env.REACT_APP_API_SERVER || API_SERVER_DEVELOPMENT
const API = process.env.REACT_APP_API_SERVER || API_SERVER_PRODUCTION

const instance = axios.create({
    baseURL: API,
    withCredentials: true, // es para establecer las cookies en axios
});

export default instance