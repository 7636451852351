import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import '@fortawesome/fontawesome-free/css/all.min.css'
import './App.css';
import { Link, Route, Routes, useParams, Outlet, NavLink, Navigate } from 'react-router-dom';
// import ImagenB64 from './pages/ImagenB64'
import { Home } from './pages/Home'
import { DeudasProvider } from './context/DeudasContext'
import { HistoricoPagos } from './pages/HistoricoPagos';
import { PagosNoSincronizados } from './pages/PagosNoSincronizados';
import {FacturaNoSincronizados} from './pages/FacturaNoSincrinizados'

import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import ProtectedRoute from './ProtectedRoute';
import Login from './pages/login/Login';


import { Usuarios } from './pages/Usuarios';
import { Pagos } from './pages/Pagos';




//Route --> cual es el path en el que tiene que renderizarse un elemento


function App() {
  return (
    
    <div className="App">
      
     
      <DeudasProvider>
      
      <div className="d-flex" id="wrapper">
        <Sidebar/>
        
        <Routes> 
          <Route path='/' element={ <Navigate to={'/login'} replace /> } />       
          <Route path='/login' element={ <Login /> } />    
         
          <Route element={ <ProtectedRoute/> }>
              <Route path='/home' element={ <Home />} />
              <Route path='/usuarios' element={ <Usuarios />} />
              <Route path='/pagos' element={<Pagos/>} />
              <Route path='/historico' element={<HistoricoPagos/>}/>
              <Route path='/noSincronizados' element={<PagosNoSincronizados/>}/>
              <Route path='/facturas' element={<FacturaNoSincronizados/>}/>
          </Route>    
          
          <Route path='*' element={ <h1>Not Found</h1> } />
        </Routes>
        </div>
      </DeudasProvider>
     
    </div>
    
  );
}

export default App;
