import React, { useState, useEffect } from 'react'
import { useDeudas } from '../context/DeudasContext'
import { useForm } from 'react-hook-form';
import { Link, useNavigate} from 'react-router-dom'

import { Container, Row, Col } from 'react-bootstrap';

export const Usuarios = () => {

  const { register, setValue,unregister, handleSubmit, formState: {errors} } = useForm();

  const { isAuthenticated, registrarUsuario, listaUsuarios,setUsuariosCargadosAlArray,usuariosCargadosAlArray, editarUsuario, eliminarUsuario,activarUsuario} = useDeudas();

  const onSubmit = handleSubmit( async(data) => {

    if (Title == 'REGISTRAR USUARIO'){
      registrarUsuario(data);
    }else{
      editarUsuario(data,UsuarioWebId);
    }
    
    document.getElementById('btnCerrarModal').click();
   // navigate('/usuarios');
  });

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = listaUsuarios==null? 0 : Math.ceil(listaUsuarios.length / 12);
  const startIndex = (currentPage - 1) * 12;
  const endIndex = startIndex + 12;
  const currentItems =listaUsuarios==null? [] : listaUsuarios.slice(startIndex, endIndex);
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };
  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  const [UsuarioWebId, setUsuarioWebId] = useState(0);
  const [UsuarioWebNombre, setUsuarioWebNombre] = useState('');
  const [UsuarioWebCorreo, setUsuarioWebCorreo] = useState(''); 
  const [UsuarioWebLogin, setUsuarioWebLogin] = useState(''); 
  const [UsuarioWebClave, setUsuarioWebClave] = useState('');
  const [RolId, setRolId] = useState(5);
  const [Title, setTitle] = useState('REGISTRAR USUARIO');
  const [Errores, setErrores]= useState(null);

  const validar=() => {
    const errors = {};
    if (UsuarioWebNombre == '') {
      errors.usuarioWebNombre = 'Campo requerido';
    }
    if (UsuarioWebCorreo == '') {
      errors.usuarioWebCorreo = 'Campo requerido';
    }
    if (UsuarioWebLogin == '') {
      errors.usuarioWebLogin = 'Campo requerido';
    }
    if (UsuarioWebClave == '') {
      errors.usuarioWebClave = 'Campo requerido';
    }
    
    setErrores(errors);
    return errors;
  }
  const registrar = ()=>{
    setErrores(null);
    const error = validar();
    if(Object.keys(error).length == 0){
    const data = {
      "UsuarioWebNombre": UsuarioWebNombre,
      "UsuarioWebCorreo": UsuarioWebCorreo,
      "UsuarioWebLogin" : UsuarioWebLogin,
      "UsuarioWebClave" : UsuarioWebClave,
      "RolId" : RolId
    }

    if (Title == 'REGISTRAR USUARIO'){
      registrarUsuario(data);
    }else{
      editarUsuario(data,UsuarioWebId);
    }
    
    document.getElementById('btnCerrarModal').click();
  }
  }

  const eliminar = (data)=> {

     eliminarUsuario(data);
  }

  const activar = (data) => {
    activarUsuario(data);
  }

 

  const openModal = (accion,usuarioWebId,usuarioWebNombre,usuarioWebCorreo,usuarioWebLogin,usuarioWebClave,rolId) =>{
    setErrores(null);
    if(accion == 1){
      setUsuarioWebId(0);
      setUsuarioWebNombre('');
      setUsuarioWebCorreo('');
      setUsuarioWebLogin('');
      setUsuarioWebClave('');
      setRolId(4);
      setTitle('REGISTRAR USUARIO')
    }else{
      if(accion == 2){
        setUsuarioWebId(usuarioWebId);
      setUsuarioWebNombre(usuarioWebNombre);
      setUsuarioWebCorreo(usuarioWebCorreo);
      setUsuarioWebLogin(usuarioWebLogin);
      setUsuarioWebClave(usuarioWebClave);
      setRolId(rolId);
      setTitle('EDITAR USUARIO')
      }
    }
  }

  const operaciones = ()=>{
    unregister("UsuarioWebNombre");
    setValue("UsuarioWebNombre",{UsuarioWebNombre})
  }


  useEffect( () => {
//setUsuariosCargadosAlArray(false) 
  }, [usuariosCargadosAlArray]);



  return (
    <>
    <div className="card shadow" style={{margin: '1%', marginRight:'2%',width: '100%'}}>
  <div className="card-header "> <Row>
      <Col> <p className='title'>GESTIONAR USUARIO</p></Col>
      <Col> <div className="d-flex justify-content-end"><button onClick={()=>openModal(1)} className="btn btn-outline-dark" data-bs-toggle="modal" data-bs-target="#exampleModal"><span className='fas fa-plus-circle'></span> Nuevo Usuario</button></div>
    </Col>
    </Row></div>
  <div className="card-body" style={{textAlign:'left'}}>
  <div className="table-responsive" >
   
   
      <table className="table table-hover">
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Usuario</th>
            <th>Rol</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
        {currentItems == null ? null : (currentItems.map( (usuario, indice) => (
          <tr key={indice}>
            <td>{indice+1}</td>
            <td>{usuario.usuarioWebNombre}</td>
            <td>{usuario.usuarioWebCorreo}</td>
            <td>{usuario.usuarioWebLogin}</td>
            <td>{usuario.rol.rolNombre}</td>
            <td>{usuario.usuarioWebEliminado == false ?'Active':'Inactive'}</td>
            <td><button onClick={()=>openModal(2,usuario.usuarioWebId,usuario.usuarioWebNombre,usuario.usuarioWebCorreo,usuario.usuarioWebLogin,usuario.usuarioWebClave,usuario.rolId)} className='btn btn-outline-dark m-2 btn-sm' data-bs-toggle="modal" data-bs-target="#exampleModal"><i className='fa-solid fa-edit'></i></button>{usuario.usuarioWebEliminado == false ? <button onClick={()=>eliminar(usuario)} className='btn btn-outline-danger btn-sm'><i className='fa-solid fa-trash'></i></button> :<button onClick={()=>activar(usuario)} className='btn btn-outline-secondary btn-sm'><i className='fas fa-check-circle'></i></button>}</td>
          </tr>
        )))}
        </tbody>
      </table>
    </div>
  </div>
  <nav>
    <ul className="pagination table-responsive">
      <li className={currentPage === 1? 'page-item m-0 disabled' : 'page-item m-0'}>
        <a className="page-link"  onClick={handlePrevPage}>Anterior</a>
      </li>
      <ul className='table-responsive p-0'>
      {pageNumbers.map((page) => (
      <li key={page} className={currentPage === page? 'page-item m-0 active' : 'page-item m-0'}><a className="page-link" onClick={() => handlePageClick(page)}>{page}</a></li>
      ))}
      </ul>
      
      <li className={currentPage === totalPages? 'page-item m-0 disabled' : 'page-item m-0'}>
        <a className="page-link" onClick={handleNextPage}>Siguiente</a>
      </li>
    </ul>
  </nav>
</div>
  


<div className="modal fade" id="exampleModal"  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg"  style={{textAlign:'left'}}>
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">{Title}</h5>
        <button id="btnCerrarModal" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">  
        <form className="row g-3 needs-validation">
          <div className="col-md-4">
            <label className="form-label">Nombre Completo</label>
            <input type="text" className={Errores == null || Errores.usuarioWebNombre == undefined? 'form-control': 'form-control is-invalid'} required value={UsuarioWebNombre} onChange={(e)=>setUsuarioWebNombre(e.target.value)}/>
            {Errores == null || Errores.usuarioWebNombre == undefined? "": 
            <div className="invalid-feedback">
              {Errores.usuarioWebNombre}
            </div>
            }
          </div>
          <div className="col-md-4">
            <label className="form-label">Correo electrónico</label>
            <div className="input-group has-validation">
              <span className="input-group-text" id="inputGroupPrepend">@</span>
              <input type="text" className={Errores == null || Errores.usuarioWebCorreo == undefined? 'form-control': 'form-control is-invalid'} required value={UsuarioWebCorreo} onChange={(e)=>setUsuarioWebCorreo(e.target.value)}/>
              {Errores == null || Errores.usuarioWebCorreo == undefined? "": 
              <div className="invalid-feedback">
                {Errores.usuarioWebCorreo}
              </div>
             }
            </div>
          </div>
          <div className="col-md-4">
            <label className="form-label">Rol</label>
            <select className="form-select" id="validationCustom04" required value={RolId} onChange={(e)=>setRolId(e.target.value)}>
              <option value="5">Supervisor</option>
              <option value="4">Vendedor</option>
            </select>
            <div className="invalid-feedback">
              Please select a valid state.
            </div>
          </div>

          <div className="col-md-4">
            <label className="form-label">Usuario</label>
            <input type="text" className={Errores == null || Errores.usuarioWebLogin == undefined? 'form-control': 'form-control is-invalid'} required value={UsuarioWebLogin} onChange={(e)=>setUsuarioWebLogin(e.target.value)}/>
            {Errores == null || Errores.usuarioWebLogin == undefined? "": 
              <div className="invalid-feedback">
                {Errores.usuarioWebLogin}
              </div>
              }
          </div>
          <div className="col-md-4">
            <label className="form-label">Contraseña</label>
            <input type="password" className={Errores == null || Errores.usuarioWebClave == undefined? 'form-control': 'form-control is-invalid'} required value={UsuarioWebClave} onChange={(e)=>setUsuarioWebClave(e.target.value)}/>
            {Errores == null || Errores.usuarioWebClave == undefined? "": 
            <div className="invalid-feedback">
              {Errores.usuarioWebClave}
            </div>
            }
          </div>
          <div className="d-flex justify-content-end">
            <button onClick={()=>registrar()} className="btn btn-dark" type="button">Enviar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
 </>
  )
}