import React, {useEffect} from 'react';
import { useDeudas } from './../../context/DeudasContext';
import { Toaster, toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom'

function Login() {

    const { register, handleSubmit, formState: {errors} } = useForm();

    const { loadingLogin, signin, errorsLogin,isAuthenticated } = useDeudas();

    const navigate = useNavigate();

    const onSubmit = handleSubmit( async(data) => {
        signin(data);
    });

    useEffect(() => {   
        if(isAuthenticated) navigate('/home') 
      }, [isAuthenticated])

  return (
    <div className='d-flex justify-content-center align-items-center movil'  style={{ height: '35rem'}}>
        <div className="bg-white p-5 rounded-2 text-secondary" style={{ width: '25rem', border: '1px solid #61A9ED', margin: '10px'}}>            

            <form onSubmit={onSubmit}>
                <div className='row mt-3'>
                    <div className='col-12 col-sm-12'>                        
                    {/* <div className='bg-danger p-2 text-white text-center my-2' key={i}> {error} </div> */}
                    {
                        errorsLogin.map((error, i) => (
                            <div className="alert alert-danger" role="alert" key={i}>
                                {error}
                            </div>
                        ))
                    }
                    </div>                    
                </div>

                <div className='row mt-3'>
                    <div className='col-12 col-sm-12'>                        
                        <h1>Login</h1>
                    </div>                    
                </div>

                <div className='row mt-3'>
                    <div className='col-12 col-sm-12'>                        
                        <input type='text' { ...register("UsuarioWebLogin", {required: true}) }
                            className='w-100 text-black px-4 py-2 rounded-md my-2' placeholder='Usuario'/>
                        {
                            errors.usuario && (
                                <p className='text-danger'> el usuario es requerido</p>
                            )
                        }
                    </div>                    
                </div>

                <div className='row mt-3 mb-4'>
                    <div className='col-12 col-sm-12'>
                    <input type='password' { ...register("UsuarioWebClave", {required: true}) }
                        className='w-100 text-black px-4 py-2 rounded-md my-2' placeholder='Password'/>    
                        {
                            errors.password && (
                                <p className='text-danger'> el password es requerido</p>
                            )
                        }                  
                    </div>                    
                </div>
                
                {
                    loadingLogin && (
                        <div className="d-flex justify-content-center">
                            <div className="loader2"></div>
                        </div> 
                    )
                }

                <div className="btn btn-info text-white w-100 mt-4">
                    <button className='btn btn-info text-white w-100 mt-4"'>Ingresar</button>
                </div>
            </form>
        </div>
        <Toaster
            position="top-right"
            toastOptions={{
                style: {
                    background: '#363636',
                    color: '#fff',
                },
            }}
        />
    </div>
  )
}

export default Login