import React, { useState, useEffect } from 'react'
import { useDeudas } from '../context/DeudasContext'
import { useForm } from 'react-hook-form';
import { Link, useNavigate} from 'react-router-dom'

import { Container, Row, Col } from 'react-bootstrap';

import Pdf from '../components/Pdf';
import Quixote from '../components/PdfPago';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import { PDFDownloadLink } from '@react-pdf/renderer';
import moment from 'moment';
import {OutTable, ExcelRenderer} from 'react-excel-renderer';

export const Pagos = () => {

  const { isAuthenticated, user, obtenerDeuda, deudas, setDeudas, obtenerClienteLote, clienteLote, setClienteLote, pagos, registrarPago,errorSap, setErrorSap, enviarPagoSAP, ErrorDeuda, setErrorDeuda,pagosVer, confRegistroPago,setConfRegistroPago, bancos, nombreCliente, setNombreCliente, registrarPagoExcel } = useDeudas();

  const {obtenerDeudaExcelVN,obtenerDeudaExcel, deudasExcel, setDeudasExcel,verifExiteLoteCliente,verificar, columnasVerificadas, listarPagos, setColumnasVerificadas, loadingLogin} = useDeudas();
  const [registExcel, setRegistExcel] = useState(false)
  const navigate = useNavigate();
  
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = pagos==null? 0 : Math.ceil(pagos.length / 11);
  const startIndex = (currentPage - 1) * 11;
  const endIndex = startIndex + 11;
  const currentItems =pagos==null? [] : pagos.slice(startIndex, endIndex);
  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  {/*const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }*/}
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };
  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };


  // Mostrar solo 10 números de página, aumentando el último si hay más páginas
  const visiblePageNumbers = totalPages > 10 ? pageNumbers.slice(0, 10) : pageNumbers;
  const lastPageNumbers = totalPages > 10? pageNumbers.slice(10) : '' ;

  // Calcular el número de la última página visible
  const lastVisiblePage = visiblePageNumbers[visiblePageNumbers.length - 2];

  const [CodigoTipoBusqueda, setCodigoTipoBusqueda] = useState('0');
  const [CodigoReferencia, setCodigoReferencia] = useState('');
  const [NroDeposito, setNroDeposito] = useState(''); 
  const [Lote, setLote] = useState('null'); 
  const [NombreLote, setNombreLote] = useState('');
  const [TipoPago, setTipoPago] = useState('');
  const [NroCuota, setNroCuota] = useState('');
  const [Banco, setBanco] = useState(0);
  const [Importe, setImporte] = useState('');
  const [FechaDeposito, setFechaDeposito] = useState('');
  const [Errores, setErrores] = useState(null);
  const [ejemploReferencia, setEjemploReferencia] = useState('');

  //Excel 
  const [cabecera, setCabecera] = useState([]);
  const [columnas, setColumnas] = useState([]);
  const [columnasM, setColumnasM] = useState([]);
  const [file, setFile] = useState("");

  const openModal = (accion) =>{
    if(accion==1){
      setCodigoTipoBusqueda('0');
      setCodigoReferencia('');
      setLote('null');
      setNroCuota('');
      setImporte('');
      setTipoPago('')
     /* const fechaHoy = new Date();
      const dia = fechaHoy.getDate();
      const mes = fechaHoy.getMonth() + 1; 
      const año = fechaHoy.getFullYear();
      const fechaActual = `${año}-${mes < 10 ? '0' + mes : mes}-${dia < 10 ? '0' + dia : dia}`*/
      const fechaActual = moment().format('YYYY-MM-DD');
      setFechaDeposito(fechaActual);
      setNroDeposito('');
      setErrores(null);
      setDeudas(null);
      setClienteLote(null);
      setErrorDeuda('');
      setNombreCliente('');
    } 
    

  }

  const openExcelModal = ()=>{
    setCabecera([]);
    setColumnas([]);
    setFile("");
    setColumnasVerificadas(null);
  }

  const validar=() => {
    const errors = {};
    if (CodigoReferencia == '') {
      errors.codigoReferencia = 'Campo requerido';
    }
    
    if (CodigoTipoBusqueda == '0') {
      errors.codigoTipoBusqueda = 'Campo requerido';
    }
    if (Lote == 'null') {
      errors.lote = 'Campo requerido';
    }
    if (NroCuota === '') {
      errors.nroCuota = 'Campo requerido';
    }
    if (NroDeposito == '') {
      errors.nroDeposito = 'Campo requerido';
    }
    if(NroDeposito.length > 8){
      errors.nroDeposito = 'La logitud debe ser < que 9'
    }
    if (FechaDeposito == '') {
      errors.fechaDeposito = 'Campo requerido';
    }
    
    if (Importe == '' || Importe < 0) {
      errors.importe = 'Importe invalido';
    }

    //const importe = /^\d*\.?\d{0,2}$/.test(Importe)
    /*if(!importe){
      errors.importe = 'El importe solo debe tener dos decimales';
    }*/
    if (TipoPago == '') {
      errors.tipoPago = 'Campo requerido';
    }
    setErrores(errors);
    return errors;
  }
 
  const registrar = ()=>{
    setErrores(null);
    //console.log(FechaDeposito)
    //quitar nombre cliente
    
    const error = validar();
   if(Object.keys(error).length == 0){
    const data = {
      "codigoTipoBusqueda": CodigoTipoBusqueda,
      "codigoReferencia": CodigoReferencia,
      "lote": NombreLote,
      "nroCuota":NroCuota,
      "tipoPago": TipoPago,
      "codigoBanco": Banco,
      "nroDeposito": NroDeposito,
      "fechaDeposito": FechaDeposito,
      "importe": Importe,
      "nombreUsuario": user.usuario,
      "Sesion": user.sesion,
    }
    //console.log(data)
    registrarPago(data);
    document.getElementById('btnCerrarModal').click();
   }

  }

  const obtenerDeudasA = (valor) => {
    setCodigoReferencia(valor)
    const data = {
      "codigoTipoBusqueda" : CodigoTipoBusqueda,
      "codigoReferencia" : valor,
      "sesion" : user.sesion,
     };
    
    obtenerClienteLote(data);
    setTipoPago('');
    setNroCuota('');
   // console.log(data)
  }

  const obtenerDeudas = () => {
    const data = {
      "codigoTipoBusqueda" : CodigoTipoBusqueda,
      "codigoReferencia" : CodigoReferencia,
      "sesion" : user.sesion,
     };
     //console.log(data)
    obtenerDeuda(data);
    setTipoPago('');
    setNroCuota('');
    //console.log(data)
   // console.log(deudas)
  }

  const obtenerLote = () => {
    const data = {
      "codigoTipoBusqueda" : CodigoTipoBusqueda,
      "codigoReferencia" : CodigoReferencia,
      "sesion" : user.sesion,
     };
     //console.log(data)
   // obtenerClienteLote(data);
    //setTipoPago('');
    //setNroCuota('');
    obtenerDeuda(data);
   
    //console.log(data)
   // console.log(deudas)
  }

  const ponerEjemplo = (tipoBusqueda)=>{
    setCodigoTipoBusqueda(tipoBusqueda);
    if(tipoBusqueda == 'CON'){
      setEjemploReferencia('Ej. TS5096016002LT')
    }else{
      setEjemploReferencia('');
    }

    
    /* const data = {
      "Usuario" : user.usuario,
      "CodigoTipoBusqueda" : documento,
      "CodigoReferencia" : CodigoReferencia,
      "CantidadMaximaDeudas" : 5, 
      "Sesion" : user.sesion,
     };*/

 /*   const data = {
      "CodigoTipoBusqueda" : documento,
      "CodigoReferencia" : CodigoReferencia,
      "Sesion" : user.sesion,
     };
    
    obtenerDeuda(data)
    setTipoPago('');
    setNroCuota('');
  */
   // verExisteLote();
   // console.log(deudas)

    /* const cuota = 'IMPORTE CUOTA'
     const lote = deudas[0].valores[0];
     const object = lote["IMPORTE CUOTA"];
     console.log(object)*/
     
  }

  const cambiarLoteCliente = (loteindice) => {
    // console.log(deudas)
     if(loteindice != "null"){
       const lote = clienteLote[loteindice].CodLote;
       //let nroCuota = deudas[loteindice].valores[0].OVE_NROCUOTA;
       setNombreLote(lote);
      // setNroCuota(nroCuota);
      // setNombreCliente(deudas[0].valores[0].CLI_NOMBRE);
     }
     setLote(loteindice);
   }


  const cambiarLote = (loteindice) => {
   /* if(loteindice != ""){
      const lote = deudas.DetalleLotes[loteindice].nombreLote;
      const nroCuota = deudas.DetalleLotes[loteindice].DetalleDeuda[0].NroCuota;
      setLote(loteindice);
      setNroCuota(nroCuota);
    }*/
    //const ver = deudas.filter(item => item.lote == 'TS5-096-016-002-LT' )
    
    if(loteindice != "null"){
      const lote = deudas[loteindice].lote;
      //let nroCuota = deudas[loteindice].valores[0].OVE_NROCUOTA;
      setNombreLote(lote);
     // setNroCuota(nroCuota);
     // setNombreCliente(deudas[0].valores[0].CLI_NOMBRE);
    }
    setLote(loteindice);
  }

  const sumarImporte = (tipo,nroCuota) => {
    let sumaMonto = 0;
    if(user.idRol == 4){
      for (const item of pagosVer) {
        if (item.lote === NombreLote && item.tipoPago == tipo && item.nroCuota === nroCuota) {
            sumaMonto += item.importe;
        }
      }
    }
    if(user.idRol == 5){
      for (const item of pagos) {
        if (item.lote === NombreLote && item.tipoPago == tipo && item.nroCuota === nroCuota) {
            sumaMonto += item.importe;
        }
      }
    }
    return sumaMonto;
  }

  const sugerirNroCuotaLote = (tipo) => {
    if(tipo == "CUO" && Lote != 'null'){
      let nroCuota = 0;
      let sumaMonto = 0;
     if(deudas != null){
      const deudasLote = deudas.filter(item => item.lote == NombreLote )
      nroCuota = deudasLote[0].valores[0].OVE_NROCUOTA;
     }
      //console.log(deudasLote)
      setNroCuota(nroCuota);
    }
    else{
      if(tipo == 'MLTA' && Lote != 'null'){
        let nroCuota = 0;
        let sumaMonto = 0;
      if(deudas != null){
        const deudasLote = deudas.filter(item => item.lote == NombreLote )
          for (const element of deudasLote[0].valores) {
            if (element["IMPMULTA"]>0){
              nroCuota = element.OVE_NROCUOTA;
              break;
            }   
          }
        }
      setNroCuota(nroCuota);
     }
    }
    setTipoPago(tipo);
  }

  const sugerirNroCuota = (tipo) => {
    if(tipo == "CUO" && Lote != 'null'){
     const loteindice = Lote;
      let nroCuota = -1;
      let sumaMonto = 0;
      for (const element of deudas[loteindice].valores) {
           sumaMonto = 0;
        if(element["IMPORTE CUOTA"]== 0){
          let encontrar;
          if(user.idRol == 4){
            encontrar = pagosVer.filter(item => item.lote === NombreLote && item.tipoPago == tipo && item.importe == 0 && item.nroCuota === element.OVE_NROCUOTA);
          }else{
            encontrar = pagos.filter(item => item.lote === NombreLote && item.tipoPago == tipo && item.importe == 0 && item.nroCuota === element.OVE_NROCUOTA);
          }
           if(encontrar.length == 0){
            nroCuota = element.OVE_NROCUOTA;
            break;
          }
        }
      
        sumaMonto = sumarImporte(tipo, element.OVE_NROCUOTA);

         if (sumaMonto < element["IMPORTE CUOTA"]){
          nroCuota = element.OVE_NROCUOTA;
          break;
         } 
      }
      setNroCuota(nroCuota);
    }
    else{
      if(tipo == 'MLTA' && Lote != 'null'){
        const loteindice = Lote;
        let nroCuota = -1;
        let sumaMonto = 0;
        for (const element of deudas[loteindice].valores) {
          sumaMonto = sumarImporte(tipo, element.OVE_NROCUOTA);
          if (sumaMonto < element["IMPMULTA"]){
            nroCuota = element.OVE_NROCUOTA;
            break;
          }   
      }
      setNroCuota(nroCuota);
     }
    }
    setTipoPago(tipo);
  }

  const enviarSAP = (data) => {
    data.sesion = user.sesion;
    enviarPagoSAP(data);
  }
  
  const ManejarExcel = (event) => {
    const file = event.target.files[0];
    setFile(event.target.value);
   // console.log(file);
   // console.log(event.target)
    ExcelRenderer(file, (err, response) => {
      if(err) {
        console.log(err);
      }else{
        //response.rows[0].push("Verificar")
        response.rows[0].splice(6, 0, "");
        response.rows[0].splice(3, 0, "");
        setCabecera(response.rows[0]);
        verifExiteLoteCliente(response.rows.slice(1))
        setColumnas(response.rows);
        //console.log(verificar)
      }
    })
  }

  const obtenerCodigoBanco = (cuenta)=>{
    const banco = bancos.filter(item => item.cuentaContable == cuenta)
    return banco[0].id;
  }

  const listarPago = ()=>{
    const dato = {
      "sesion": user.sesion,
    }
    listarPagos(dato);
  }

  const registrarExcel = async ()=>{
    let longitud = columnasVerificadas.length;
    let indice = 0;
    setRegistExcel(true);
    while(indice < longitud){
      let indiceInterno = 0;
      let flag = true;
      while(flag){
        if(indiceInterno < columnasVerificadas.length){
          if(columnasVerificadas[indiceInterno][11] == true){
            const dato = {};
            const column = columnasVerificadas[indiceInterno];
            for(let i=0;i<column.length;i++){
              switch(i){
                case 0:
                  dato.codigoTipoBusqueda = column[i];
                  break;
                case 1:
                  dato.codigoReferencia = column[i];
                  break;
                case 2:
                  dato.lote = column[i];
                  break;
                case 4:
                  if(column[i]=='CUOTA'){
                    dato.tipoPago = 'CUO';
                  }else {
                    if (column[i]=='MULTA'){
                    dato.tipoPago = 'MLTA';
                    }else{
                      dato.tipoPago = '';
                    }
                  }
                  break;
                case 5:
                  dato.nroCuota = column[i];
                  break;
                case 6:
                  const idBanco = obtenerCodigoBanco(column[i]);
                  dato.codigoBanco = idBanco;
                  break;
                case 8:
                  dato.nroDeposito = column[i];
                  break;
                case 9:
                  dato.fechaDeposito = column[i];
                  break;
                case 10:
                  dato.importe = column[i];
                  break;
              }
            }
            dato.nombreUsuario = user.usuario;
            dato.Sesion = user.sesion;
            if(dato.tipoPago !=''){
              await registrarPagoExcel(dato, indiceInterno);
            }
            
           // console.log(dato)
            flag = false;
          }else{
            indiceInterno++;
          }
        }else{
          flag = false;
        }  
      }
    indice++;
  }

   /* for(const column of columnas){
      const dato = {};
      for(let i=0;i<column.length;i++){
        switch(i){
          case 0:
            dato.codigoTipoBusqueda = column[i];
            break;
          case 1:
            dato.codigoReferencia = column[i];
            break;
          case 2:
            dato.lote = column[i];
            break;
          case 3:
            dato.tipoPago = column[i];
            break;
          case 4:
            dato.nroCuota = column[i];
            break;
          case 5:
            const idBanco = obtenerCodigoBanco(column[i]);
            dato.codigoBanco = idBanco;
            break;
          case 6:
            dato.nroDeposito = column[i];
            break;
          case 7:
            dato.fechaDeposito = column[i];
            break;
          case 8:
            dato.importe = column[i];
            break;
        }
      }
      dato.nombreUsuario = user.usuario;
      dato.Sesion = user.sesion;
      registrarPagoExcel(dato);
     // console.log(dato)
    }*/
    setRegistExcel(false);
  }

  const verifExiteLotexCliente = (columns)=>{
    for(const column of columns){
      let dato = {}
      let lote = "";
      
      const col = column.slice(0,3);
      for(let i=0;i< col.length;i++){
        switch(i){
          case 0:
            dato.CodigoTipoBusqueda = column[i];
            break;
          case 1:
            dato.CodigoReferencia = column[i];
            break;
          case 2:
            lote = column[i];
            break;
      }
      dato.Sesion = user.sesion;
      const existe =  obtenerDeudaExcelVN(dato);
      //console.log(existe)
    }
    return true;
  }
}
const vef = ()=>{
  console.log(columnasVerificadas)
  console.log(columnasVerificadas[0][9]);
    if(columnasVerificadas[1][9] == true){
      console.log("entro")
      columnasVerificadas.splice(1, 1);
    }
  
  console.log(columnasVerificadas)
}

  useEffect(() => {
    if(errorSap != ''){
      setTimeout(() => {
        setErrorSap('')
        }, 10000);
    }
    if(confRegistroPago != ''){
      setTimeout(() => {
        setConfRegistroPago('')
        }, 10000);
    }
  },[errorSap,confRegistroPago,loadingLogin])

  return (
    <>
<div className="card shadow" style={{margin: '1%', marginRight:'2%',width: '100%'}}>
  <div className="card-header "> <Row>
      <Col> <p className='title'>GESTIONAR PAGOS</p></Col>
      <Col><div className="d-flex justify-content-end"> 
          <button onClick={()=>openModal(1)} className="btn btn-outline-dark" data-bs-toggle="modal" data-bs-target="#exampleModal"><span className='fas fa-plus-circle'></span> Nuevo Pago</button>
          <button style={{marginLeft: '3px'}} onClick={()=>openExcelModal()} className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target="#exportModal"><span className='fas fa-plus-circle'></span> Importar Pago</button>
          <div style={{marginLeft: '3px'}}>
            <ReactHTMLTableToExcel 
              table = "tablaGestPagos"
              filename="pagos"
              sheet="Pagina 1"
              className="btn btn-success fas fa-file-excel "
              buttonText=" Excel"
            />
          </div>
          </div>
      </Col>
    </Row>
  </div>
  <div className="card-body" style={{textAlign:'left'}}>
  {errorSap !=''?  <div className="alert alert-danger" role="alert">{errorSap}</div> : <div></div>}
  {confRegistroPago !=''?  <div className="alert alert-success" role="alert">{confRegistroPago}</div> : <div></div>}
    <div className="table-responsive" >
      <table className="table table-hover">
        <thead>
          <tr>
            <th>NroInterno</th>
            <th>Fecha</th>
            <th>Codigo</th>
            <th>Cliente</th>
            <th>Lote</th>
            <th>Importe</th>
            <th>Nro Deposito</th>
            <th>Vendedor</th>
            <th>Estado</th>
            <th>Nro Documento</th>
            <th>Nro Doc (SAP)</th>
            <th>Enviar SAP</th>
            <th>Imprimir</th>
          </tr>
        </thead>
        <tbody>
        {pagos == null ? null : (currentItems.map( (pago, indice) => (
          <tr key={indice}>
            <td>{pago.id}</td>
            <td>{new Date(pago.fechaCreacion).getDate()}/{new Date(pago.fechaCreacion).getMonth()+1}/{new Date(pago.fechaCreacion).getFullYear()}</td>
            <td>{pago.codigoCliente}</td>
            <td>{pago.nombreCliente}</td>
            <td>{pago.lote}</td>
            <td>{pago.importe}</td>
            <td>{pago.nroDeposito}</td>
            <td>{pago.nombreUsuario}</td>
            <td>{pago.enviadoSAP == 'F' ?'No sincronizado':pago.enviadoSAP == 'C'? 'Cancelado':'Sincronizado'}</td>
            <td>{pago.nroDocumentoSAP}</td>
            <td>{pago.nroDocumento}</td>
            <td>{pago.enviadoSAP == 'F' ? <button onClick={()=>enviarSAP(pago)} disabled={loadingLogin} className='btn btn-outline-primary m-2 btn-sm'><i className='fas fa-paper-plane'></i></button> : null}</td>
            <td>{pago.enviadoSAP == 'F' || pago.enviadoSAP =='C'? null:
              <PDFDownloadLink document={<Pdf content={pago} tipo={pago.tipoPago=='CUO'? 'Cuota':'Multa'}/>} fileName="ComprobantePagos.pdf">
                {({loading, url, error, blob}) => 
                loading ? (<button className='btn btn-outline-secondary m-2 btn-sm'><i className='fas fa-print'></i></button>) : (<button className='btn btn-outline-secondary m-2 btn-sm'><i className='fas fa-print'></i></button>)
                }
              </PDFDownloadLink>
              
            }</td>
          </tr>)))}
        </tbody>
      </table> 
      
    </div>
  </div>
  <table id="tablaGestPagos" style={{display: 'none'}}>
        <thead>
          <tr>
            <th>NroInterno</th>
            <th>Fecha</th>
            <th>Codigo</th>
            <th>Cliente</th>
            <th>Lote</th>
            <th>Importe</th>
            <th>Vendedor</th>
            <th>Estado</th>
            <th>Nro Documento</th>
            <th>Nro Doc (SAP)</th>
            <th>Tipo pago</th>
            <th>Nro Cuota</th>
          </tr>
        </thead>
        <tbody>
        {pagos == null ? null : (pagos.map( (pago, indice) => (
          <tr key={indice}>
            <td>{pago.id}</td>
            <td>{new Date(pago.fechaCreacion).getDate()}/{new Date(pago.fechaCreacion).getMonth()+1}/{new Date(pago.fechaCreacion).getFullYear()}</td>
            <td>{pago.codigoCliente}</td>
            <td>{pago.nombreCliente}</td>
            <td>{pago.lote}</td>
            <td>{pago.importe}</td>
            <td>{pago.nombreUsuario}</td>
            <td>{pago.enviadoSAP == 'F' ?'No sincronizado':pago.enviadoSAP == 'C'? 'Cancelado':'Sincronizado'}</td>
            <td>{pago.nroDocumentoSAP}</td>
            <td>{pago.nroDocumento}</td>
            <td>{pago.tipoPago == 'CUO'? 'Cuota':'Multa'}</td>
            <td>{pago.nroCuota}</td>
           </tr>
        )))}
        </tbody>
  </table> 

  {/*<nav aria-label="Page navigation">
      <ul className="pagination">
      <li className={currentPage === 1? 'page-item disabled' : 'page-item'}>
        <a className="page-link"  onClick={handlePrevPage}>Anterior</a>
      </li>
        {currentPage < 10 && visiblePageNumbers.map((page) => (
          <li key={page} className={currentPage === page ? 'page-item active' : 'page-item'}>
            <a className="page-link" onClick={() => handlePageClick(page)}>
              {page}
            </a>
          </li>
        ))} 
        {currentPage <10 && <li className="page-item"><a className="page-link">...</a></li>}
        {currentPage >= 10 && (<li className="page-item">
              <a className="page-link" onClick={() => handlePageClick(1)}>{1}</a>
            </li>)}
        {currentPage >=10 && <li className="page-item"><a className="page-link">...</a></li>}
        {currentPage >= 10 && (<li className={currentPage === 10 ? 'page-item active' : 'page-item'}>
              <a className="page-link" onClick={() => handlePageClick(10)}>{10}</a>
            </li>)}
        
        {currentPage >= 10 && lastPageNumbers.map((page) => (
          <li key={page} className={currentPage === page ? 'page-item active' : 'page-item'}>
            <a className="page-link" onClick={() => handlePageClick(page)}>
              {page}
            </a>
          </li>
        ))}
        
        <li className={currentPage === totalPages? 'page-item disabled' : 'page-item'}>
        <a className="page-link" onClick={handleNextPage}>Siguiente</a>
      </li>
      </ul>
  </nav>*/}
  <nav>
    <ul className="pagination table-responsive" >
      <li className={currentPage === 1? 'page-item m-0 disabled' : 'page-item m-0'}>
        <a className="page-link"  onClick={handlePrevPage}>Anterior</a>
      </li>
      <ul className='table-responsive p-0'>
      {pageNumbers.map((page) => (
      <li key={page} className={currentPage === page? 'page-item m-0 active' : 'page-item m-0'}><a className="page-link" onClick={() => handlePageClick(page)}>{page}</a></li>
      ))}
      </ul>
        
      <li className={currentPage === totalPages? 'page-item m-0 disabled' : 'page-item m-0'}>
        <a className="page-link" onClick={handleNextPage}>Siguiente</a>
      </li>
    </ul>
  </nav>
  <div style={{textAlign :'right'}}>
    <span>{`Página ${currentPage} de ${totalPages}`}</span>
  </div>
</div>

<div className="modal fade" id="exampleModal"  aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg"  style={{textAlign:'left'}}>
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">REGISTRAR PAGO</h5>
        <button id="btnCerrarModal" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <form className="row g-3 needs-validation" noValidate >
          <div className="col-md-4">
            <label className="form-label">Tipo Documento</label>
            <select className={Errores == null || Errores.codigoTipoBusqueda == undefined? 'form-control': 'form-control is-invalid'} required value={CodigoTipoBusqueda} onChange={(e)=>ponerEjemplo(e.target.value)}>
                <option value="0">SELECCIONE UN TIPO DE DOCUMENTO</option>
                <option value="COD">Código cliente</option>
                <option value="DID">Documento de identidad</option>
                <option value="CON">Lote</option>
            </select>
            {Errores == null || Errores.codigoTipoBusqueda == undefined? "": 
            <div className="invalid-feedback">
              {Errores.codigoTipoBusqueda}
            </div>
            }
          </div>
          <div className="col-md-4">
            <label className="form-label">Código de Referencia</label>
            <input type="text" className={Errores == null || Errores.codigoReferencia == undefined? 'form-control': 'form-control is-invalid'} required value={CodigoReferencia} placeholder={ejemploReferencia} onChange={(e)=>obtenerDeudasA(e.target.value)} onBlur={()=>obtenerLote()} />
            {Errores == null || Errores.codigoReferencia == undefined? "": 
            <div className="invalid-feedback">
              {Errores.codigoReferencia}
            </div>
            }
             <div>{nombreCliente}</div>
    
          </div>
        
          <div className="col-md-4">
            <label className="form-label">Lote</label>
            <select className={ErrorDeuda == ''? Errores == null || Errores.lote == undefined? 'form-control': 'form-control is-invalid' : 'form-control is-invalid'} required value={Lote} onChange={(e)=>cambiarLoteCliente(e.target.value)}>
              <option value="null">SELECCIONE UN LOTE</option>
              {clienteLote == null ? <option value="null">Cargando ...</option> : (clienteLote.map( (deuda, indice) => (
              <option key={indice} value={indice}>{deuda.CodLote}</option>
              )))}
            </select>
            {Errores == null || Errores.lote == undefined? "": 
            <div className="invalid-feedback">
              {Errores.lote}
            </div>
            }
            {ErrorDeuda == '' ? "": 
            <div className="invalid-feedback">
              {ErrorDeuda}
            </div>
            }
          </div>
          <div className="col-md-4">
            <label className="form-label">Tipo Pago</label>
            <select className={Errores == null || Errores.tipoPago == undefined? 'form-control': 'form-control is-invalid'} required value={TipoPago} onChange={(e)=>sugerirNroCuotaLote(e.target.value)}>
                <option value="">SELECCIONE UN TIPO DE PAGO</option>
                <option value="CUO">Cuota</option>
                <option value="MLTA">Multa</option>
            </select>
            {Errores == null || Errores.tipoPago == undefined? "": 
              <div className="invalid-feedback">
                {Errores.tipoPago}
              </div>
              }
          </div>
          <div className="col-md-4">
            <label className="form-label">Nro de Cuota</label>
            <div className="input-group has-validation">
              <span className="input-group-text" id="inputGroupPrepend">#</span>
              <input type="number" className={Errores == null || Errores.nroCuota == undefined? 'form-control': 'form-control is-invalid'}  required value={NroCuota} onChange={(e)=>setNroCuota(e.target.value)}/>
              {Errores == null || Errores.nroCuota == undefined? "": 
              <div className="invalid-feedback">
                {Errores.nroCuota}
              </div>
              }
            </div>
          </div> 
          <div className="col-md-4">
            <label className="form-label">Banco</label>
            <select className="form-select" required value={Banco} onChange={(e)=>setBanco(e.target.value)}>
              <option value="0">SELECCIONE UN BANCO</option>
            {bancos == null ? null : (bancos.map( (banco, indice) => (
              <option key={indice} value={banco.id}>{banco.descripcion}</option>
            )))}
            </select>
            <div className="invalid-feedback">
              Please select a valid state.
            </div>
          </div>
          <div className="col-md-4">
            <label className="form-label">Nro de Deposito</label>
            <div className="input-group has-validation">
              <span className="input-group-text" >#</span>
              <input type="number" className={Errores == null || Errores.nroDeposito == undefined? 'form-control': 'form-control is-invalid'} required value={NroDeposito} onChange={(e)=>setNroDeposito(e.target.value)} />
              {Errores == null || Errores.nroDeposito == undefined? "": 
              <div className="invalid-feedback">
                {Errores.nroDeposito}
              </div>
              }
            </div>   
          </div>
          <div className="col-md-4">
            <label className="form-label">Fecha</label>
            <input type="date" className={Errores == null || Errores.fechaDeposito == undefined? 'form-control': 'form-control is-invalid'} required value={FechaDeposito} onChange={(e)=>setFechaDeposito(e.target.value)} />
            {Errores == null || Errores.fechaDeposito == undefined? "": 
            <div className="invalid-feedback">
              {Errores.fechaDeposito}
            </div>
            }
          </div>
          <div className="col-md-4">
            <label className="form-label">Importe</label>
            <div className="input-group has-validation">
              <input type="number" step="0.01" min="0" className={Errores == null || Errores.importe == undefined? 'form-control': 'form-control is-invalid'} required value={Importe} onChange={(e)=>setImporte(e.target.value)} />
              {Errores == null || Errores.importe == undefined? "": 
              <div className="invalid-feedback">
                {Errores.importe}
              </div>
              }
            </div>
           
          </div>
          <div className="d-flex justify-content-end">
            <button id="btn-enviar" onClick={()=>registrar()} className="btn btn-dark" type="button">Enviar</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div className="modal fade" id="exportModal"  aria-labelledby="exportModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl"  style={{textAlign:'left'}}>
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">IMPORTAR PARA REGISTRAR PAGO</h5>
        <button id="btnExportCerrarModal" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>listarPago()}></button>
      </div>
      <div className="modal-body">
        <form className="was-validated">
          <div className="mb-3">
            <input type="file" accept=".xlsx, .xls" className="form-control" aria-label="file example" value={file} required onChange={(e)=>ManejarExcel(e)} />
          </div>
        </form>  
        <div className="d-grid gap-2">
          {columnasVerificadas!=null &&
          (registExcel? <button className="btn btn-primary" type="button" disabled>REGISTRANDO PAGOS</button> : <button className="btn btn-primary" type="button" onClick={()=>registrarExcel()} >Registrar Pagos</button>)
            
          } 
        </div>
        {
          loadingLogin && (
              <div className="d-flex justify-content-center">
                  <div className="loader2"></div>
                  <div>Verificando datos</div>
              </div> 
          )
        }
        {/*<button className="btn btn-primary" type="button" onClick={()=>vef()}>Verificar</button>*/}
        <div className="table-responsive" >
            <table className="table table-hover">
              <thead>
                <tr>
                {cabecera.map((valor, indice) => (
                    <th key={indice}>{valor}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {columnasVerificadas ==null? null: columnasVerificadas.map((col, indice)=>(
                  <tr key={indice}>
                    { col.map((dato, indice)=>(
                      <td key={indice}>{(indice==11? dato==true? <button type="button" className="btn btn-success">
                      <i className="fas fa-check"></i> Correcto</button>: <button type="button" className="btn btn-danger"><i className="fas fa-times"></i>Incorrecto</button>:indice == 7? dato== false? <button type="button" className="btn btn-danger"><i className="fas fa-times"></i>cuenta inexistente</button>:dato:indice == 3? dato==false? <button type="button" className="btn btn-danger"><i className="fas fa-times"></i>cliente o lote inexistente</button>: dato:dato )}</td>
                    ))} 
                  </tr>
                ))}
                
              </tbody>
            </table> 
          </div>
      </div>
    </div>
  </div>
</div>
  </>
  )
}