import React from 'react';
import { Nav } from 'react-bootstrap';
import { useDeudas } from '../context/DeudasContext'
import {Link, NavLink, useLocation } from 'react-router-dom'

const Sidebar = () => {
  const { isAuthenticated, user, logout, listarUsuario, listarPagos, listarPagoNoEnviadoSap, listarPagoVer,listarBanco, listarFacturaWeb } = useDeudas();

  const location = useLocation();

  const isRootPath = location.pathname === '/';
  const isLoginPath = location.pathname === '/login';

  const usuarios = ()=>{
    const data= {
      "sesion": user.sesion,
     }
     listarUsuario(data);
  }
  const pagos = ()=>{
      const data= {
        "Sesion": user.sesion,
       }
      const dato= {
        "sesion": user.sesion,
       }
       listarPagos(data);
       listarUsuario(dato);
       listarBanco(data);
       if(user.idRol == 4){
      /*  const data= {
          "Sesion": user.sesion,
         }*/
        //listarPagoVer(data);
       }
  }

  const listarFacturas = ()=>{
    const data = {
      "usuarios": "",
      "sesion": user.sesion
    }
    listarFacturaWeb(data)
  }

  const pagosNoEnvSap = ()=>{
      const data= {
        "Sesion": user.sesion,
       }
      const dato= {
        "sesion": user.sesion,
       }
       listarPagoNoEnviadoSap(data);
       listarUsuario(dato);
    
  }

  return (
   isAuthenticated ? (
    <div className="border-right sidebar">
      <div style={{paddingTop:'10px',paddingBottom:'10px', borderBottom: '2px solid #0dcaf0',fontWeight: 'bold',fontSize:'18px',textAlign:'center'}}>{user.usuario}</div>
      <div className="sidebar-heading">Menu </div>
      <nav className="flex-column">
       {user.idRol == 5? <li><NavLink className={ ({ isActive }) => { return isActive ? 'is-active': undefined } }  to='/usuarios' onClick={ () => { usuarios() }}>Gestionar Usuarios</NavLink></li> : ''}
        <li><NavLink className={ ({ isActive }) => { return isActive ? 'is-active': undefined } } to='/pagos' onClick={()=>pagos()} >Gestionar Pagos</NavLink></li>
        <li> <NavLink className={ ({ isActive }) => { return isActive ? 'is-active': undefined } } to='/historico' onClick={()=>pagos()}>Histórico de pagos</NavLink> </li>
        <li> <NavLink className={ ({ isActive }) => { return isActive ? 'is-active': undefined } } to='/noSincronizados' onClick={()=>pagosNoEnvSap()}>Pagos no sincronizados</NavLink> </li>
        <li> <NavLink className={ ({ isActive }) => { return isActive ? 'is-active': undefined } } to='/facturas' onClick={()=>listarFacturas()}>Sincronizar SAP</NavLink> </li>
         
        <Nav.Link to='/' onClick={ () => { logout() }} style={{borderTop: '2px solid #0dcaf0',color:'white'}}>Salir</Nav.Link>
                      
      </nav>
    </div>
    ) : 
   (
    <div className='logo'></div>
   )
  );
};

export default Sidebar;