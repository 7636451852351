import React, { useEffect, useState } from 'react';
import { obtenerDeuda } from './../api/auth'
import { useDeudas } from './../context/DeudasContext'
import { useNavigate } from 'react-router-dom'
import './../components/Loader.css';
import { Toaster, toast } from 'react-hot-toast'
import './home.css'

export const Home = () => {

    const { getDeudas, deudasCargadasAlArray, loading, errorConsultarDeuda, setErrorConsultarDeuda, setIsAuthenticated } = useDeudas();

    const navigate = useNavigate();



  return (
   <div><></></div>
  )
}

