import React from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import { useDeudas } from './context/DeudasContext'

function ProtectedRoute() {
    const { isAuthenticated} = useDeudas();

    if(!isAuthenticated){
        return <Navigate to={'/'} replace />  //replace --> es para que no vuelva a la ruta anterior, se sobreescriba
    }

    return (
        <Outlet/> /* indica donde se va a renderizar el componete anidado, CONTINUA CON EL COMPONETE QUE ESTA ADENTRO*/
    )
}

export default ProtectedRoute